.custom-control {
  position: relative;
  z-index: 0;
  display: block;
  //min-height: 1.5rem;
  //padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  print-color-adjust: exact;
  text-align: center;
}